newWindowPopUp = function(url, target, features, element) { 
	openWindow(url, target, features).then(ref => {
		const event = new CustomEvent('newWindowOpen', { detail: { ref: ref } });
		element.dispatchEvent(event);
	});
} 

openWindow = function(url, target, features) {
	return new Promise((resolve, reject) => {
		const result = window.open(url, target, features);
		resolve(result);
	}); 
}

// TODO: refactor
getBrowserName = function() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
		return 'edge';
      case agent.indexOf('opr') > -1:
        return 'opera';
      case agent.indexOf('chrome') > -1:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
}