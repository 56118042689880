/* 
  To preload tinymce editor css and js for plugins 
  we need to initialize Editor instance 1 time
  in another way user will see delay on pages that implement <editor> component
  because that component will try to load css and js
*/
(function() {
  'use strict';

  var TINYMCE_EDITOR_ID = 'tinymce-editor-preloader';
  var TINYMCE_EDITOR_ID_SELECTOR = '#' + TINYMCE_EDITOR_ID;
  // to keep index.html clear we will create tmp-div
  var tinymceEditorPreloaderElement = document.createElement('div');
  // add this div id for editor init and hide it in DOM
  tinymceEditorPreloaderElement.setAttribute('id', TINYMCE_EDITOR_ID);
  tinymceEditorPreloaderElement.style.display = 'none';
  // append elemnt just in the end of document
  document.body.appendChild(tinymceEditorPreloaderElement);
  // init editor on created element
  window.tinymce
    .init({
      selector: TINYMCE_EDITOR_ID_SELECTOR,
      plugins: 'link lists table codesample',
      resize: false,
      menubar: '',
      height: '100%',
      base_url: '/tinymce',
      suffix: '.min',
      toolbar:
        'bold | italic | underline | numlist | bullist | h1 | link | table | codesample',
    })
    .then(() => {
      // remove editor before deleting and editor-element to avoid memory leaks and errors
      window.tinymce.remove(TINYMCE_EDITOR_ID_SELECTOR);
      // remove created element after initialization
      document.body.removeChild(tinymceEditorPreloaderElement);
    });
})();
